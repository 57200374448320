<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card
      :loading="loading"
      :disabled="loading"
      elevation="2"
      class="pa-5 mt-5"
    >
      <div v-if="quotation.id">
        <v-card-title class="font-weight-600">
          見積詳細
        </v-card-title>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              :md="quotation.result_final_date ? 6 : 12"
              class="flex-grow-1"
            >
              <v-card
                class="primary border-rounded-15px padding-custom-box h-100"
              >
                <div
                  class="d-flex justify-space-between flex-wrap mb-5 white--text"
                >
                  <div class="font-size-18px font-weight-500">
                    ID: {{ quotation.id }}
                  </div>
                  <div class="font-size-12px font-weight-500">
                    <div>IP: {{ quotation.ip_address }}</div>
                    <div>
                      {{ quotation.location_name
                      }}{{ quotation.city != '' ? ', ' + quotation.city : '' }}
                    </div>
                  </div>
                </div>

                <div
                  class="d-flex justify-space-between flex-wrap mb-5 white--text"
                >
                  <div class="mt-3 font-size-14px font-weight-500">
                    <div>ステータス：{{ quotation.status }}</div>
                    <div v-if="quotation.email">
                      メールアドレス:
                      <a
                        class="white--text"
                        :href="
                          'mailto:' +
                            quotation.email +
                            '?subject=【三光住建】 外壁塗装のお見積り・ご相談について'
                        "
                        >{{ quotation.email }}</a
                      >
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="flex-grow-1"
              v-if="quotation.result_final_date"
            >
              <v-card
                :class="
                  checkedBtnClasses +
                    ' border-rounded-15px padding-custom-box h-100 d-flex'
                "
              >
                <div class="my-auto mx-auto w-100 text-center">
                  <div
                    :class="
                      checkedBtnClasses +
                        ' font-size-18px font-weight-600 mb-2 pb-5'
                    "
                  >
                    {{ checkedBtbText }}
                  </div>
                  <v-btn
                    v-model="checkedAdmin"
                    @click="changeAdminCheck"
                    medium
                    elevation="3"
                    class="white font-size-18px font-weight-600"
                    max-width="260px"
                    width="100%"
                  >
                    対応完了
                  </v-btn>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="border-line"></div>

        <v-container>
          <v-row>
            <v-col
              cols="12"
              :md="quotation.result_first_date ? 6 : 12"
              class="flex-grow-1"
            >
              <v-card
                class="sky--bg-light border-rounded-15px padding-custom-box h-100"
              >
                <div class="d-flex justify-space-between ">
                  <div class="font-size-18px font-weight-600">簡易見積</div>
                  <div class="font-size-14px font-weight-500">
                    {{ quotation.result_first_date }}
                  </div>
                </div>
                <div class="d-flex flex-column" style="height: 90%;">
                  <h2
                    class="font-size-36px font-weight-600 my-3 blue--text-light"
                  >
                    {{ quotation.total_value }}円
                  </h2>
                  <ul
                    class="list--group font-size-14px font-weight-500 mt-auto"
                  >
                    <li>坪数： {{ quotation.total_area }}</li>
                    <li>階数：{{ quotation.number_floors_jp }}階</li>
                    <li v-if="quotation.outer_wall_type_jp">
                      外壁種類：{{ quotation.outer_wall_type_jp }}
                    </li>
                    <li v-if="quotation.sealing_jp">
                      シーリングの劣化：{{ quotation.sealing_jp }}
                    </li>
                    <li>
                      屋根塗装希望と種類：{{ quotation.roof_paint_jp }},
                      {{ quotation.roof_type_jp }}
                    </li>
                  </ul>
                </div>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="flex-grow-1"
              v-if="quotation.result_second_date"
            >
              <v-card
                class="sky--bg-light border-rounded-15px padding-custom-box h-100"
              >
                <div class="d-flex justify-space-between ">
                  <div class="font-size-18px font-weight-600">詳細見積</div>
                  <div class="font-size-14px font-weight-500">
                    {{ quotation.result_second_date }}
                  </div>
                </div>
                <div class="d-flex flex-column" style="height: 90%;">
                  <h2
                    class="font-size-36px font-weight-600 my-3 blue--text-light"
                  >
                    {{ quotation.second_step_total_value }}円
                  </h2>
                  <ul
                    class="list--group font-size-14px font-weight-500 mt-auto"
                  >
                    <li>築年数：{{ quotation.building_age }}</li>
                    <li v-if="quotation.wall_paint_type_jp">
                      外壁塗料種類：{{ quotation.wall_paint_type_jp }}
                    </li>
                    <li v-if="quotation.roof_paint_type_jp">
                      屋根塗料種類：{{ quotation.roof_paint_type_jp }}
                    </li>
                  </ul>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="border-line" v-if="quotation.result_final_date"></div>

        <v-container v-if="quotation.result_final_date">
          <v-row>
            <v-col cols="12" md="12" class="flex-grow-1">
              <v-card
                class="sky--bg-light border-rounded-15px padding-custom-box h-100"
              >
                <div class="d-flex justify-space-between ">
                  <div class="font-size-18px font-weight-600 mb-5">
                    相談 / 問い合わせ
                  </div>
                </div>

                <v-row>
                  <v-col cols="12" md="6">
                    <ul class="list--group font-size-14px font-weight-500">
                      <li>
                        氏名：{{ quotation.name + ' ' + quotation.furigana }}
                      </li>
                      <li>電話番号：{{ quotation.phone }}</li>
                      <li>
                        メールアドレス：<a
                          :href="
                            'mailto:' +
                              quotation.user_form_email +
                              '?subject=【三光住建】 外壁塗装のお見積り・ご相談について'
                          "
                          >{{ quotation.user_form_email }}</a
                        >
                      </li>
                      <li>
                        住所：<a
                          :href="
                            'https://www.google.com/maps/place/' +
                              quotation.zipcode +
                              ' ' +
                              quotation.street_address1 +
                              ' ' +
                              quotation.street_address2
                          "
                          target="_blank"
                        >
                          {{ quotation.zipcode }}
                          {{ quotation.street_address1 }}
                          {{ quotation.street_address2 }}
                        </a>
                      </li>
                    </ul>
                  </v-col>

                  <v-col cols="12" md="6">
                    <ul class="list--group font-size-14px font-weight-500">
                      <li
                        v-if="
                          quotation.vs_choice1_date != '' &&
                            quotation.vs_choice1_date != null
                        "
                      >
                        連絡日の第一希望：{{ quotation.vs_choice1_date }}
                        {{ quotation.vs_choice1_time }}
                      </li>
                      <li
                        v-if="
                          quotation.vs_choice2_date != '' &&
                            quotation.vs_choice2_date != null
                        "
                      >
                        連絡日の第二希望：{{ quotation.vs_choice2_date }}
                        {{ quotation.vs_choice2_time }}
                      </li>
                      <li
                        v-if="
                          quotation.vs_choice3_date != '' &&
                            quotation.vs_choice3_date != null
                        "
                      >
                        連絡日の第三希望：{{ quotation.vs_choice3_date }}
                        {{ quotation.vs_choice3_time }}
                      </li>
                    </ul>
                  </v-col>
                </v-row>
                <p class="mt-5 font-weight-500 font-size-14px">
                  メモ：{{ quotation.remark }}
                </p>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card>
  </div>
</template>

<script>
import api from '@/services/api-methods'
import moment from 'moment'
import { EventBus } from '@/services/event-bus.js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: '見積管理',
          disabled: false,
          to: { name: 'Quote' },
          exact: true
        },
        {
          text: '見積詳細',
          disabled: false,
          to: { name: 'QuoteView' },
          exact: true
        }
      ],
      checkedBtbText: '対応が必要です',
      checkedBtnClasses: 'red lighten-4 red--text text--darken-4',
      checkedAdmin: false,
      quotation: [],
      quotationList: [],
      loading: true,
      quoteID: this.$route.params.id
    }
  },
  mounted() {
    this.getQuotation()
  },
  methods: {
    deleteThisQuote() {
      this.$swal
        .fire({
          title: 'この操作を実行してもよろしいですか？',
          text: '実行すると元に戻せません。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '削除する',
          cancelButtonText: 'キャンセル'
        })

        .then(result => {
          if (result.isConfirmed) {
            this.loading = true
            api
              .post('/quotations/delete-multiple', [{ id: this.quotation.id }])
              .then(response => {
                if (response.data.status == 'success') {
                  this.$swal.fire('', 'Quatations has been deleted.', 'success')

                  let payload = {
                    status: response.data.status,
                    message: response.data.message
                  }
                  this.$store.commit('setAlert', payload)
                  this.$router.push({ name: 'Quote' })
                }
              })
              .catch(err => {
                console.log(err)
                this.loading = false
              })
          }
        })
    },
    getQuotation() {
      this.loading = true
      api
        .get('/quotations/' + this.quoteID)
        .then(response => {
          this.quotation = response.data.data
          this.loading = false
          let quoteAdminStatusDate = moment(this.quotation.admin_action_date)
          if (
            quoteAdminStatusDate.isValid() &&
            this.quotation.admin_action_date != ''
          ) {
            this.checkedBtbText =
              '対応完了 ' +
              moment(this.quotation.admin_action_date).format('YYYY/MM/DD')
            this.checkedBtnClasses =
              'green lighten-4 green--text text--darken-4'
            this.checkedAdmin = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    changeAdminCheck() {
      console.log(this.checkedAdmin)
      this.checkedAdmin = this.checkedAdmin ? false : true
      if (this.checkedAdmin) {
        let postData = {
          id: this.quoteID,
          status: '対応完了',
          date: moment().format('YYYY/MM/DD')
        }
        this.updateQuoteStatus(postData)
        this.checkedBtnClasses = 'green lighten-4 green--text text--darken-4'
      } else {
        let postData = {
          id: this.quoteID,
          status: '相談/問い合せ',
          date: null
        }
        this.updateQuoteStatus(postData, true)
        this.checkedBtnClasses = 'red lighten-4 red--text text--darken-4'
      }
    },
    updateQuoteStatus(postData, date = false) {
      api
        .post('/admin-status-update', postData)
        .then(response => {
          if (date) {
            this.checkedBtbText = '対応が必要です'
            EventBus.$emit('status_count', 'increment')
          } else {
            this.checkedBtbText = '対応完了 ' + response.data.date
            EventBus.$emit('status_count', 'decrement')
          }
        })
        .catch(err => {
          this.$swal.fire('アクションなし！', err, 'error')
        })
    }
  }
}
</script>
<style lang="css" scoped>
.border-rounded-15px {
  border-radius: 15px;
}
.sky--bg-light {
  background: #f3f8ff !important;
}
.blue--text-light {
  color: #224b8f;
}
.h-100 {
  height: 100%;
}
.padding-custom-box {
  padding: 25px 50px;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-size-36px {
  font-size: 36px;
}
.font-size-18px {
  font-size: 18px;
}
.font-size-16px {
  font-size: 16px;
}
.font-size-14px {
  font-size: 14px;
}
.font-size-12px {
  font-size: 12px;
}
ul.list--group {
  list-style: none;
  padding: 0;
}
.list--group li {
  padding: 4px 0;
}
.w-100 {
  width: 100%;
}
.border-line {
  border: 1px solid #edf3f6;
  width: 100%;
  margin: 1rem 0;
}
</style>
